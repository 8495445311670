<template>
  <v-row v-if="enableBranches" justify="center">
    <v-dialog
      v-model="isOpen"
      :persistent="!selectedBranch?.id"
      scrollable
      width="auto"
    >
      <template #activator="{ props: propsTemp }">
        <div v-bind="propsTemp" class="title-style">
          <div
            class="flex items-center text-xs px-2 py-1 sm:text-base cursor-pointer"
          >
            <div>
              {{
                selectedBranch?.name?.trim() ||
                  selectedBranch?.id ||
                  t('select_branch')
              }}
            </div>
            <v-icon size="small" icon="mdi-chevron-down"></v-icon>
          </div>
        </div>
      </template>
      <v-card>
        <v-card-title>{{ t('select_branch') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="min-height: 100px">
          <v-radio-group v-model="branchId" column>
            <v-radio
              v-for="(branch, index) in store?.branches"
              :key="index"
              :value="branch.id"
            >
              <template #label>
                <div>
                  <div class="font-bold">
                    {{ branch.name }}
                  </div>
                  <div class="text-xs">
                    {{ branch.address }}
                  </div>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div
            class="flex w-full justify-center"
            :class="{ 'cursor-not-allowed': !branchId }"
          >
            <v-btn
              prepend-icon="mdi-check"
              class="my-theme-btn-class"
              :disabled="!branchId"
              :class="{ disable: !branchId }"
              @click="selectBranch()"
            >
              {{ t('save') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import type { Store, BranchStore } from '~~/composables/useMenuModel'
const isOpen = ref(false)
const route = useRoute()
const branchId = ref(0)
const enableBranches = ref(false)
const { t } = useI18n()

const props = defineProps<{
  store?: Store
  selectedBranch?: BranchStore
}>()

function toggleSupportBranches() {
  if (props.store?.active_package?.package?.support_branches) {
    if (props.store?.branches?.length) {
      enableBranches.value = true
      branchId.value = useBranchId() || branchId.value
      if (!props.selectedBranch?.id) {
        isOpen.value = true
      }
    }
  }
}
watch(
  () => route.query.branch_id,
  () => toggleSupportBranches()
)
async function selectBranch() {
  if (route.query.branch_id !== branchId.value.toString()) {
    if (useIsDomain()) {
      const { url: urlLocal } = useDomainHost()
      reloadNuxtApp({
        path: `${urlLocal + '?branch_id=' + branchId.value}`,
        ttl: 1
      })
    } else {
      const newPath = useQueryNavigationPath({ branch_id: branchId.value })
      await useRouter().replace(newPath)
      reloadNuxtApp({
        path: route.fullPath,
        ttl: 1
      })
    }
  } else {
    isOpen.value = false
  }
}

onMounted(() => {
  nextTick(() => {
    toggleSupportBranches()
  })
})
</script>

<style>
.my-theme-btn-class {
  @apply bg-nav_color text-text_alt_color !important;
}

.my-theme-btn-class.disable {
  @apply opacity-50  !important;
}
.title-style {
  @apply bg-bg_color rounded-full  border border-button_color text-text_color;
}
</style>
